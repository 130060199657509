<template>
    <div class="container">
        <div class="row">
            <!-- Hauptbereich -->
            <div class="col-lg-9">
                <div class="row">
                    <div class="col-md-4">
                        <a href="https://ipcheck.vegabyte.de" target="_blank" class="tool-card-ref">
                            <div class="tool-card">
                                <h5>IP Checker</h5>
                                <p>Überprüfe welche IP's und Ports bei einer Website genutzt werden. Auch extern eingebundene Dateien werden dabei berücksichtigt, um eine Optimale Freigabe in der Firewall zu ermöglichen.</p>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-4">
                        <a href="#portcheck" class="tool-card-ref">
                            <div class="tool-card" v-on:click="show('portcheck')">
                                <h5>Port Checker</h5>
                                <p>Überprüfe die offenen Ports auf deinem Gerät.</p>
                            </div>
                        </a>
                    </div>
                    <div class="col-md-4">
                        <a href="#mousemover" class="tool-card-ref">
                            <div class="tool-card" v-on:click="show('mousemover')">
                                <h5>Mouse Mover</h5>
                                <p>Simuliere Mausbewegungen, sowie Klicks, um den Bildschirm aktiv zu halten.</p>
                            </div>
                        </a>
                    </div>
                    <!--
                    <div class="col-md-4">
                        <div class="tool-card">
                            <h5>Clipper</h5>
                            <p>Ein Addin für Browser, mit dem man Texte vorschreiben kann und immer wieder einfügt über einen Rechtsklick.</p>
                            <p>Aktuell befindet sich das Tool in der Entwicklung</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div></div>
                        <div class="tool-card">
                            <h5>MP4 Converter</h5>
                            <p>Konvertiere Videos in das MP4-Format.</p>
                            <p>Aktuell befindet sich das Tool in der Entwicklung</p>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="tool-card">
                            <h5>Schulungs Tool</h5>
                            <p>Füge Mitarbeiter hinzu, baue Fragebögen, sende Ergebnisse, etc.</p>
                            <p>Aktuell befindet sich das Tool in der Entwicklung</p>
                        </div>
                    </div>
                    -->
                </div>
            </div>

            <!--
            <div class="col-lg-3">
                <div class="ad-container">
                    <p>Werbung</p>
                    Google Ads Script hier einfügen
                </div>
                <div class="ad-container">
                    <p>Werbung</p>
                    Google Ads Script hier einfügen
                </div>
            </div>
            -->
        </div>
    </div>
</template>

<script>
    import Windows from "../windows.js"

    export default Windows.register({
        name: "home",
        props: {
            data: String
        },
        data() {
            return {
            }
        },
        methods: { 
            show: function (window) {
                components.Framework.show(window)
            },
        },
        created: function () {
            
        },
        mounted: function () {

        },
        destroyed: function () {
        }
    })
</script>

<style>

</style>
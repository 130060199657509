<template>
    <div>
        <section class="content bg-dark" style="padding: 20px 0px;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card card-primary card-outline">
                            <div class="card-body box-profile">
                                <h3 class="text-center software-version">Port Check</h3>
                                <ul class="list-group list-group-unbordered mb-3">
                                    <li class="list-group-item">
                                        <b>
                                            <i class="fa-solid fa-network-wired"></i> 
                                            IP oder URL des Servers
                                        </b>
                                        </br> 
                                        <span class="float-right" style="margin-top: 10px;">
                                            <input type="text" class="portcheck-input" v-model="networkAdress" placeholder="127.0.0.1" />
                                        </span>
                                    </li>
                                    <li class="list-group-item">
                                        <b>
                                            <i class="fa-solid fa-list-radio"></i> 
                                            Prüfungsart
                                        </b>
                                        </br>
                                        <span class="float-right">
                                            <select class="portcheck-input" v-model="portCheckSelection">
                                                <option value="nothing">--Bitte Wähle eine Optioon aus--</option>
                                                <option value="specific">Speziellen Port prüfen</option>
                                                <option value="application">Anwendungsport</option>
                                            </select>
                                        </span>
                                    </li>
                                    <li class="list-group-item" v-if="portCheckSelection == 'specific'">
                                        <b>
                                            <i class="fa-solid fa-shield-check"></i> 
                                            Port
                                        </b>
                                        </br>
                                        <span class="float-right">
                                            <input type="number" class="portcheck-input" v-model="portCheckPort" placeholder="8080" step="1" />
                                            <span><button class="portcheck-button" v-on:click="startPortcheck()">Überprüfen</button></span>
                                        </span>
                                    </li>
                                    <li class="list-group-item" v-if="portCheckSelection == 'application'">
                                        <b>
                                            <i class="fa-solid fa-shield-check"></i> 
                                            Anwendung
                                        </b>
                                        </br>
                                        <span class="float-right">
                                            <select class="portcheck-input" v-model="portCheckApplication">
                                                <option value="HTTP">HTTP</option>
                                                <option value="HTTPS">HTTPS</option>
                                                <option value="MySQL">MySQL</option>
                                                <option value="FTP">FTP</option>
                                                <option value="SFTP">SFTP</option>
                                                <option value="LDAP">LDAP</option>
                                                <option value="LDAPS">LDAPS</option>
                                                <option value="CS2">CS2</option>
                                                <option value="soon">#Bald mehr</option>
                                            </select>
                                            </br>
                                            </br>
                                            <span v-if="portCheckApplication != 'soon'">Ports: {{ getPorts() }}</span>
                                            <span><button class="portcheck-button" v-on:click="startPortcheck()">Überprüfen</button></span>
                                        </span>
                                    </li>
                                </ul>
                                <ul class="list-group list-group-unbordered mb-3">
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-calendar mr-1"></i> Veröffentlichung</b></br> <span
                                            class="float-right">23.08.2024</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-body portcheck-content">
                                Der Status des Ports wird hier eingetragen. Die Seite muss nicht neugeladen werden. Die Historie bleibt bestehen, solang sie auf der Seite sind.
                                </br>
                                <!-- 
                                <b><u>Fehlerliste:</u></b>
                                <div class="error-portcheck-output">
                                    
                                </div>
                                -->
                                </br>
                                <b><u>TCP Ergebnisse:</u></b>
                                <div class="tcp-portcheck-output">
                                    
                                </div>
                                </br>
                                <b><u>UDP Ergebnisse:</u></b>
                                <div class="udp-portcheck-output">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Windows from "../windows.js"
    import jQuery from "../../external/jquery-3.2.1.min.js";
    let $ = jQuery;

    export default Windows.register({
        name: "portcheck",
        props: {
            data: String
        },
        data() {
            return {
                networkAdress: "",
                portCheckSelection: "application",
                portCheckPort: "",
                portCheckApplication: "",
            }
        },
        methods: {
            show: function (window) {
                components.Framework.show(window)
            },
            getPorts(){
                switch(this.portCheckApplication){
                    case 'HTTP':
                        return [80];
                        break;
                    case 'HTTPS':
                        return [80, 443];
                        break;
                    case 'MySQL':
                        return [3306];
                        break;
                    case 'FTP':
                        return [21];
                        break;
                    case 'SFTP':
                        return [22, 990];
                        break;
                    case 'LDAP':
                        return [636];
                        break;
                    case 'LDAPS':
                        return [3269];
                        break;
                    case 'CS2':
                        return [27015, 27031, 27032, 27033, 27034, 27035, 27036 ];
                        break;
                    default: 
                        return '';
                        break;
                }
            },
            startPortcheck: function(){
                var ports = [];
                if(this.portCheckSelection == "application") ports = this.getPorts()
                else ports.push(this.portCheckPort)

                ports.forEach(function (port) {
                    components.portcheck.checkPort("tcp://" + components.portcheck.networkAdress, port, "TCP");
                    components.portcheck.checkPort("udp://" + components.portcheck.networkAdress, port, "UDP");
                });
            },
            checkPort: function (ip, port, type) {
                $.ajax({
                    url: 'https://tools.vegabyte.de/portscan.php',
                    type: 'GET',
                    data: { ip: ip, port: port},
                    dataType: 'json',
                    success: function (response) {
                        if (response.status === 'open' || response.status == 'open') {
                            var resultText = type + ' Port <b>' + port + '</b> auf IP <b>' + ip.split('/')[2] + '</b> ist <span class="portcheck-open">offen</span>.<br>';
                        } else if (response.status === 'closed' || response.status == 'closed') {
                            var resultText = type + ' Port <b>' + port + '</b> auf IP <b>' + ip.split('/')[2] + '</b> ist <span class="portcheck-closed">geschlossen</span>.<br>';
                        } else {
                            var resultText = 'Fehler bei der Prüfung des ' + type + ' Ports <b>' + port + '</b> auf IP <b>' + ip.split('/')[2] + '</b>.<br>';
                        }
                        if(type == "UDP")
                            $('.udp-portcheck-output').append(resultText);
                        else if(type == "TCP")
                            $('.tcp-portcheck-output').append(resultText);
                        else
                            $('.error-portcheck-output').append(resultText);
                    },
                    error: function () {
                        var resultText = 'Fehler bei der Prüfung des Ports <b>' + port + '</b> auf IP <b>' + ip.split('/')[2] + '</b>.<br>';
                        $('.error-portcheck-output').append(resultText);
                    }
                });
            }
        },
        mounted: function () {},
        destroyed: function () {}
    })
</script>

<style scoped>
    @import "../../style/timeline.css";
</style>
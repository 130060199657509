<template>
    <div>
        <section class="content bg-dark" style="padding: 20px 0px;">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card card-primary card-outline">
                            <div class="card-body box-profile">
                                <div class="text-center">
                                    <img class="profile-user-img img-fluid img-circle profilepicture"
                                        :src="require('@/assets/tools/mousemover/logo.png')" alt="Mouse Mover">
                                </div>
                                <h3 class="text-center software-version">Mouse Mover</h3>
                                <ul class="list-group list-group-unbordered mb-3">
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-calendar mr-1"></i> Veröffentlichung</b></br> <span
                                            class="float-right">21.08.2024</span>
                                    </li>
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-timer mr-1"></i> Aktuellste Version</b></br><span
                                            class="float-right">{{ version }}</span>
                                    </li>
                                    <li class="list-group-item">
                                        <b><i class="fa-solid fa-download"></i> Download der neusten Version</b></br><span
                                            class="float-right"><a href="https://link-center.net/1236584/mousemover-v014" target="_blank">Download</a></span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-8">
                        <div class="card" style="margin-bottom: 10px;">
                            <div class="card-body">
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="timeline">
                                    <!-- timeline time label -->
                                    <div class="time-label"> <span class="text-bg-danger">2024</span> </div>
                                    <!-- /.timeline-label -->
                                    
                                    <!-- START timeline item -->
                                    <div> 
                                        <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                08 Okt 2024
                                            </span>
                                            <h3 class="timeline-header no-border"> <a href="https://link-center.net/1236584/mousemover-v014" target="_blank">Version 0.1.4</a></h3>
                                            <div class="timeline-header no-border"> 
                                                - X und Y können nun per Button hinterlegt werden
                                                <br>- Bei Mausbewegungen wird der Mausmover pausiert </br>
                                                - Layout angepasst und übersichtlicher gestaltet
                                                <br>- Fehlerbehebung bei zu niedrigen Werten, dafür eine extra Abfrage </br>
                                                - Das Fenster erscheint nun beim stoppen des Maus Movers
                                                <br> </br>
                                                <br><img class="preview-image" :src="require('@/assets/tools/mousemover/v0.1.4.png')"></br>
                                            </div>
                                        </div>
                                    </div> 
                                    <!-- END timeline item -->
                                    
                                    <!-- START timeline item -->
                                    <div> 
                                        <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                25 Sep 2024
                                            </span>
                                            <h3 class="timeline-header no-border"> <a href="https://link-center.net/1236584/mousemover-v013" target="_blank">Version 0.1.3</a></h3>
                                            <div class="timeline-header no-border"> 
                                                - Der Mousemover kann über einen rechtsklick über die versteckten Icons gestartet & gestoppt werden
                                                <br>- Den Menüaufbau angepasst </br>
                                            </div>
                                        </div>
                                    </div> 
                                    <!-- END timeline item -->
                                    
                                    <!-- timeline item -->
                                    <div> 
                                        <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                22 Aug 2024
                                            </span>
                                            <h3 class="timeline-header no-border"> <a href="https://link-center.net/1236584/mousemover-v012" target="_blank">Version 0.1.2</a></h3>
                                            <div class="timeline-header no-border"> 
                                                - Das Klicken kann deaktiviert werden
                                                <br>- Die Anwendung hat nun ein anderes Icon </br>
                                                - Die Bilder wurden überarbeitet
                                            </div>
                                        </div>
                                    </div> 
                                    <!-- END timeline item -->
                                    <!-- timeline item -->
                                    <div> 
                                        <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                21 Aug 2024
                                            </span>
                                            <h3 class="timeline-header no-border"> <a href="https://link-hub.net/1236584/mousemover-v011" target="_blank">Version 0.1.1</a></h3>
                                            <div class="timeline-header no-border"> 
                                                - Auswahl, wo die Maus sich bewegen soll
                                                <br>- Klick der Maus hinzugefügt, mit Auswahl ob Rechtsklick, Linksklick oder Doppelklick </br>
                                                - Timer hinzugefügt, der innerhalb der Zeit Random eine Zeit auswählt
                                                <br>- Beim schließen per "X" wird die Anwendung versteckt in den versteckten Icons </br>
                                                - Hilfemenü kann über die Verstecken Icons aufgerufen werden
                                                <br>- Über Beenden bei den versteckten Icons, kann die Software beendet werden </br>
                                                - Beim Klicken auf "Start" wird der Text zu "Stop" geändert. Beim erneuten Klicken auf "Stop", stoppt die Anwendung und der Text wird zu "Start"
                                            </div>
                                        </div>
                                    </div> 
                                    <!-- END timeline item -->
                                    <!-- timeline item -->
                                    <div> 
                                        <i class="timeline-icon bi bi-person text-bg-success"></i>
                                        <div class="timeline-item"> <span class="time"> <i class="bi bi-clock-fill"></i>
                                                20 Aug 2024
                                            </span>
                                            <h3 class="timeline-header no-border"> <a href="https://link-hub.net/1236584/mousemover-v010" target="_blank">Version 0.1.0</a></h3>
                                            <div class="timeline-header no-border"> 
                                                - Fenster Gerüst gebaut
                                                <br>- Mouse Move hinzugefügt </br>
                                            </div>
                                        </div>
                                    </div> 
                                    <!-- END timeline item -->
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </section>
    </div>
</template>

<script>
    import Windows from "../windows.js"

    export default Windows.register({
        name: "mousemover",
        props: {
            data: String
        },
        data() {
            return {
                version: "0.1.4",
            }
        },
        methods: {
            show: function (window) {
                components.Framework.show(window)
            },
        },
        mounted: function () {},
        destroyed: function () {}
    })
</script>

<style scoped>
    @import "../../style/timeline.css";
</style>
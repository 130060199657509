var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('section',{staticClass:"content bg-dark",staticStyle:{"padding":"20px 0px"}},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"card card-primary card-outline"},[_c('div',{staticClass:"card-body box-profile"},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"profile-user-img img-fluid img-circle profilepicture",attrs:{"src":require('@/assets/tools/mousemover/logo.png'),"alt":"Mouse Mover"}})]),_c('h3',{staticClass:"text-center software-version"},[_vm._v("Mouse Mover")]),_c('ul',{staticClass:"list-group list-group-unbordered mb-3"},[_vm._m(0),_c('li',{staticClass:"list-group-item"},[_vm._m(1),_c('br'),_c('span',{staticClass:"float-right"},[_vm._v(_vm._s(_vm.version))])]),_vm._m(2)])])])]),_c('div',{staticClass:"col-md-8"},[_vm._m(3),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"timeline"},[_vm._m(4),_c('div',[_c('i',{staticClass:"timeline-icon bi bi-person text-bg-success"}),_c('div',{staticClass:"timeline-item"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"timeline-header no-border"},[_vm._v(" - X und Y können nun per Button hinterlegt werden "),_c('br'),_vm._v("- Bei Mausbewegungen wird der Mausmover pausiert "),_c('br'),_vm._v(" - Layout angepasst und übersichtlicher gestaltet "),_c('br'),_vm._v("- Fehlerbehebung bei zu niedrigen Werten, dafür eine extra Abfrage "),_c('br'),_vm._v(" - Das Fenster erscheint nun beim stoppen des Maus Movers "),_c('br'),_vm._v(" "),_c('br'),_c('br'),_c('img',{staticClass:"preview-image",attrs:{"src":require('@/assets/tools/mousemover/v0.1.4.png')}}),_c('br')])])]),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"list-group-item"},[_c('b',[_c('i',{staticClass:"fa-solid fa-calendar mr-1"}),_vm._v(" Veröffentlichung")]),_c('br'),_vm._v(" "),_c('span',{staticClass:"float-right"},[_vm._v("21.08.2024")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b',[_c('i',{staticClass:"fa-solid fa-timer mr-1"}),_vm._v(" Aktuellste Version")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"list-group-item"},[_c('b',[_c('i',{staticClass:"fa-solid fa-download"}),_vm._v(" Download der neusten Version")]),_c('br'),_c('span',{staticClass:"float-right"},[_c('a',{attrs:{"href":"https://link-center.net/1236584/mousemover-v014","target":"_blank"}},[_vm._v("Download")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"card-body"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"time-label"},[_c('span',{staticClass:"text-bg-danger"},[_vm._v("2024")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"time"},[_c('i',{staticClass:"bi bi-clock-fill"}),_vm._v(" 08 Okt 2024 ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('h3',{staticClass:"timeline-header no-border"},[_c('a',{attrs:{"href":"https://link-center.net/1236584/mousemover-v014","target":"_blank"}},[_vm._v("Version 0.1.4")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('i',{staticClass:"timeline-icon bi bi-person text-bg-success"}),_c('div',{staticClass:"timeline-item"},[_c('span',{staticClass:"time"},[_c('i',{staticClass:"bi bi-clock-fill"}),_vm._v(" 25 Sep 2024 ")]),_c('h3',{staticClass:"timeline-header no-border"},[_c('a',{attrs:{"href":"https://link-center.net/1236584/mousemover-v013","target":"_blank"}},[_vm._v("Version 0.1.3")])]),_c('div',{staticClass:"timeline-header no-border"},[_vm._v(" - Der Mousemover kann über einen rechtsklick über die versteckten Icons gestartet & gestoppt werden "),_c('br'),_vm._v("- Den Menüaufbau angepasst "),_c('br')])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('i',{staticClass:"timeline-icon bi bi-person text-bg-success"}),_c('div',{staticClass:"timeline-item"},[_c('span',{staticClass:"time"},[_c('i',{staticClass:"bi bi-clock-fill"}),_vm._v(" 22 Aug 2024 ")]),_c('h3',{staticClass:"timeline-header no-border"},[_c('a',{attrs:{"href":"https://link-center.net/1236584/mousemover-v012","target":"_blank"}},[_vm._v("Version 0.1.2")])]),_c('div',{staticClass:"timeline-header no-border"},[_vm._v(" - Das Klicken kann deaktiviert werden "),_c('br'),_vm._v("- Die Anwendung hat nun ein anderes Icon "),_c('br'),_vm._v(" - Die Bilder wurden überarbeitet ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('i',{staticClass:"timeline-icon bi bi-person text-bg-success"}),_c('div',{staticClass:"timeline-item"},[_c('span',{staticClass:"time"},[_c('i',{staticClass:"bi bi-clock-fill"}),_vm._v(" 21 Aug 2024 ")]),_c('h3',{staticClass:"timeline-header no-border"},[_c('a',{attrs:{"href":"https://link-hub.net/1236584/mousemover-v011","target":"_blank"}},[_vm._v("Version 0.1.1")])]),_c('div',{staticClass:"timeline-header no-border"},[_vm._v(" - Auswahl, wo die Maus sich bewegen soll "),_c('br'),_vm._v("- Klick der Maus hinzugefügt, mit Auswahl ob Rechtsklick, Linksklick oder Doppelklick "),_c('br'),_vm._v(" - Timer hinzugefügt, der innerhalb der Zeit Random eine Zeit auswählt "),_c('br'),_vm._v("- Beim schließen per \"X\" wird die Anwendung versteckt in den versteckten Icons "),_c('br'),_vm._v(" - Hilfemenü kann über die Verstecken Icons aufgerufen werden "),_c('br'),_vm._v("- Über Beenden bei den versteckten Icons, kann die Software beendet werden "),_c('br'),_vm._v(" - Beim Klicken auf \"Start\" wird der Text zu \"Stop\" geändert. Beim erneuten Klicken auf \"Stop\", stoppt die Anwendung und der Text wird zu \"Start\" ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('i',{staticClass:"timeline-icon bi bi-person text-bg-success"}),_c('div',{staticClass:"timeline-item"},[_c('span',{staticClass:"time"},[_c('i',{staticClass:"bi bi-clock-fill"}),_vm._v(" 20 Aug 2024 ")]),_c('h3',{staticClass:"timeline-header no-border"},[_c('a',{attrs:{"href":"https://link-hub.net/1236584/mousemover-v010","target":"_blank"}},[_vm._v("Version 0.1.0")])]),_c('div',{staticClass:"timeline-header no-border"},[_vm._v(" - Fenster Gerüst gebaut "),_c('br'),_vm._v("- Mouse Move hinzugefügt "),_c('br')])])])
}]

export { render, staticRenderFns }
<template>
    <div class="">
        <nav class="navbar navbar-expand-lg navbar-dark">
            <div class="container-fluid">
                <a href="https://vegabyte.de/" target="_blank"><img class="navbar-brand navbar-logo" :src="require('@/assets/logo_with_name.png')" /></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarNav">
                    <ul class="navbar-nav ms-auto">
                        <a class="nav-link" href="#home" v-on:click="show('home')" id="navbarDropdown2">
                            Home
                        </a>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Security
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown2">
                                <li><a class="dropdown-item" href="#">Sub-Link 1</a></li>
                                <li><a class="dropdown-item" href="#">Sub-Link 2</a></li>
                                <li><a class="dropdown-item" href="#">Sub-Link 3</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Converter
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown3">
                                <li><a class="dropdown-item" href="#">Sub-Link 1</a></li>
                                <li><a class="dropdown-item" href="#">Sub-Link 2</a></li>
                                <li><a class="dropdown-item" href="#">Sub-Link 3</a></li>
                            </ul>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                Download Tools
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown2">
                                <li><a class="dropdown-item" href="#">Sub-Link 1</a></li>
                                <li><a class="dropdown-item" href="#">Sub-Link 2</a></li>
                                <li><a class="dropdown-item" href="#">Sub-Link 3</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <main>
            <div class="construction-message" v-if="showMaintenance">
                <button class="close-btn" v-on:click="showMaintenance = !showMaintenance">×</button>
                <h1>Die Seite im Wartungsmodus</h1>
                <p>Wir arbeiten derzeit an unserer Website. Bitte schauen Sie später wieder vorbei.</p>
            </div>
            <component :is="windowName"></component>
        </main>

        <footer class="bg-footer">
            <div class="container">
                <div class="row">
                    <div class="col-md-3">
                        <p class="text-light">&copy; 2024 VegaByte. Alle Rechte vorbehalten.</p>
                    </div>
                    <div class="col-md-6 text-md-right">
                    </div>
                    <div class="col-md-3">
                        <a href="#impressum" v-on:click="show('impressum')">Impressum</a>
                        <a href="#privacypolicy" v-on:click="show('privacypolicy')"><br>Datenschutzerklärung</br></a>
                    </div>
                </div>
            </div>
        </footer>

    </div>
</template>

<script>
    import Components from "./components"

    //Wichtige Seiten
    import impressum from "./important/impressum.vue"
    import privacypolicy from "./important/privacypolicy.vue"

    //Generelle Seiten
    import home from "./pages/home.vue"

    //Download Tools
    import mousemover from "./download/mousemover.vue"

    //Security Tools
    import portcheck from "./security/portcheck.vue"
    
    //Converter Tools
    

    export default Components.register({
        name: "Framework",
        data: function () {
            return {
                windowName: "home",
                showMaintenance: false,
                componentList:  ['impressum', 'privacypolicy', 'home', 'mousemover', 'portcheck' ],
            }
        },
        methods: {
            show: function (window) {
                this.windowName = window
            },
            close: function () {
                this.windowName = ""
            },
            toggleMenu: function () {
                var displayStyle = document.getElementsByClassName("collapse")[0].style.display;

                if (displayStyle == "block") document.getElementsByClassName("collapse")[0].style.display = "none";
                else document.getElementsByClassName("collapse")[0].style.display = "block";

            },
            checkURL: function () {
                if(window.location.href.includes('#')){
                    var currentPage = window.location.href.split("#")[1];
                    for (var i = 0; i < this.componentList.length; i++) {
                        if (currentPage.toLowerCase() == this.componentList[i].toLowerCase()) {
                            this.show(this.componentList[i])
                            i = this.componentList.length;
                        }
                    }
                } else {
                    this.show("home");
                }
            },
            checkHashChangeURL: function (change) {
                if(change.includes('#')){
                    var currentPage = change.split("#")[1];
                    for (var i = 0; i < this.componentList.length; i++) {
                        if (currentPage.toLowerCase() == this.componentList[i].toLowerCase()) {
                            this.show(this.componentList[i])
                            i = this.componentList.length;
                        }
                    }
                } else {
                    this.show("home");
                }
            }
        },
        components: {
            //WICHTIG
            impressum,
            privacypolicy,

            //Seiten
            home,

            //Download Tools
            mousemover,

            //Security 
            portcheck,

            //Converter
        },
        mounted() {
            window.addEventListener('hashchange', function (e) {
                components.Framework.checkHashChangeURL(e.newURL);
            });
        },
        created() {
            this.checkURL();
        },
    })
</script>